main {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-keyframe {
  animation-name: rotate;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-name: opacity;
}
.dice {
  width: 60px;
  height: 60px;
  position: relative;
  transform-style: preserve-3d;
  transform: rotateX(0deg) rotateY(0deg) translateX(0);
  animation-name: rotate;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  > div {
    height: 60px;
    width: 60px;
    position: absolute;
    background: #f76331;
  }
  span {
    width: 8px;
    height: 8px;
    background: #fff;
    border-radius: 50%;
    display: block;
    position: absolute;
  }
  .front {
    transform: rotateY(0deg) translateZ(30px);
    span {
      &:nth-child(1) {
        top: 10px;
        left: 12px;
      }
      &:nth-child(2) {
        top: 10px;
        right: 12px;
      }
      &:nth-child(3) {
        top: 26px;
        left: 12px;
      }
      &:nth-child(4) {
        top: 26px;
        right: 12px;
      }
      &:nth-child(5) {
        bottom: 10px;
        left: 12px;
      }
      &:nth-child(6) {
        bottom: 10px;
        right: 12px;
      }
    }
  }
  .back {
    transform: rotateX(180deg) translateZ(30px);
    span {
      top: 26px;
      left: 26px;
    }
  }
  .right {
    transform: rotateY(90deg) translateZ(30px);
    span {
      &:nth-child(1) {
        top: 12px;
        left: 12px;
      }
      &:nth-child(2) {
        top: 12px;
        right: 12px;
      }
      &:nth-child(3) {
        top: 26px;
        left: 26px;
      }
      &:nth-child(4) {
        bottom: 12px;
        left: 12px;
      }
      &:nth-child(5) {
        bottom: 12px;
        right: 12px;
      }
    }
  }
  .left {
    transform: rotateY(-90deg) translateZ(30px);
    span {
      &:nth-child(1) {
        top: 12px;
        right: 12px;
      }
      &:nth-child(2) {
        bottom: 12px;
        left: 12px;
      }
    }
  }
  .top {
    transform: rotateX(90deg) translateZ(30px);
    span {
      &:nth-child(1) {
        top: 12px;
        right: 12px;
      }
      &:nth-child(2) {
        bottom: 12px;
        left: 12px;
      }
      &:nth-child(3) {
        bottom: 26px;
        left: 26px;
      }
    }
  }
  .bottom {
    transform: rotateX(-90deg) translateZ(30px);
    span {
      &:nth-child(1) {
        top: 12px;
        right: 12px;
      }
      &:nth-child(2) {
        top: 12px;
        left: 12px;
      }
      &:nth-child(3) {
        bottom: 12px;
        left: 12px;
      }
      &:nth-child(4) {
        bottom: 12px;
        right: 12px;
      }
    }
  }
}
.button {
  position: fixed;
  bottom: 20px;
  background: #f76939;
  padding: 20px 40px;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  &:hover {
    background: darken(#f76939, 20);
  }
}
@keyframes opacity {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0.5;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  80% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes rotate {
  0% {
    transform: rotateX(90deg) rotateY(360deg) rotateZ(0deg) translateX(0);
  }
  35% {
    transform: rotateX(-180deg) rotateY(-90deg) rotateZ(0deg) translateX(0);
  }
  45% {
    transform: rotateX(-180deg) rotateY(-90deg) rotateZ(0deg) translateX(0);
  }
  65% {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(-360deg) translateX(0);
  }
  75% {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(-360deg) translateX(0);
  }
  100% {
    transform: rotateX(90deg) rotateY(360deg) rotateZ(0deg) translateX(0);
  }
}
