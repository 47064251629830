.navList {
  a {
    color: #ffffff;
  }
  .navItem {
    &:not(:first-child) {
      margin-left: 24px;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .navList {
    .navItem {
      &:not(:first-child) {
        margin-left: 18px;
      }
    }
  }
}

@media only screen and (max-width: 1080px) {
  .navList {
    margin-top: 64px;
    margin-left: 34px;
    .navItem {
      &:not(:first-child) {
        margin-left: 0;
        margin-top: 50px;
      }
    }
  }
}
