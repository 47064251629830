* {
  font-family: "LEMON MILK", "Inter", sans-serif;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

button {
  user-select: none;
}

html {
  font-size: 12px;
  color: #ffffff;
  font-family: "LEMON MILK";
  // line-height: 130%;
  font-variant: none;
  font-smooth: always;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body,
#root {
  min-height: 100%;
  background: #a00000;
}

body::-webkit-scrollbar {
  width: 8px;
  background: #000000;
}

body::-webkit-scrollbar-track {
  background: 0 0;
}

body::-webkit-scrollbar-thumb {
  background: #8a0000;
  border-radius: 16px;
}

body::-webkit-scrollbar-thumb:hover {
  background: #8a0000;
}

.app-container {
  overflow: hidden;
}

.container {
  width: 1280px;
  max-width: 100%;
}

.color1 {
  color: #ffffff;
}

.color2 {
  color: #740000;
}

.font-family-GAME-ROBOT {
  font-family: "GAME ROBOT";
}

.font-weight-b {
  font-variation-settings: "wght" 700;
  font-weight: bold;
}

.font-14 {
  font-size: 14px;
  line-height: 19px;
}

.font-16 {
  font-size: 16px;
  line-height: 22px;
}

.font-20 {
  font-size: 20px;
  line-height: 27px;
}

.font-25 {
  font-size: 25px;
  line-height: 34px;
}

.font-30 {
  font-size: 30px;
  line-height: 41px;
}

.font-36 {
  font-size: 36px;
  line-height: 41px;
}

.font-46 {
  font-size: 46px;
  line-height: 53px;
}

.font-48 {
  font-size: 48px;
  line-height: 55px;
}

.font-60 {
  font-size: 60px;
  line-height: 81px;
}

.font-64 {
  font-size: 64px;
  line-height: 74px;
}

.font-96 {
  font-size: 96px;
  line-height: 110px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-16 {
  margin-left: 16px;
}

.ml-12 {
  margin-left: 12px;
}

.mr-16 {
  margin-right: 16px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

a {
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
}

.flex-1 {
  flex: 1;
}

.pointer {
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input {
  font-size: 30px;
  color: #ffffff;
  font-family: "GAME ROBOT";
  padding-left: 12px;
  background: transparent;
  border: none;
  outline: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: rgba(255, 255, 255, 0.8);
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: rgba(255, 255, 255, 0.8);
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: rgba(255, 255, 255, 0.8);
}
input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(255, 255, 255, 0.8);
}

@media only screen and (max-width: 1080px) {
}
