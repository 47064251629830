.header-view {
  .header {
    padding: 16px 36px 16px 32px;
    width: 100vw;
    background: #8a0000;
  }
}

@media only screen and (max-width: 1280px) {
  .header-view {
    .header {
      padding: 16px 18px 16px 16px;
    }
  }
}

@media only screen and (max-width: 680px) {
  .header-view {
    .header {
      padding: 20px 16px;
    }
  }
}
