.footer-view {
  .footer {
    grid-template-columns: repeat(2, 375px);
    grid-gap: 40px 48px;
    padding: 34px 16px 52px 16px;
    background: #730000;
    .dots {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #ffffff;
    }
  }
  .links {
    grid-template-columns: repeat(4, 60px);
    grid-gap: 20px;
    padding: 36px 16px;
    background: #2d0000;
    .twitter {
      &:hover {
        path {
          fill: #1da1f2;
        }
      }
    }
    .discord {
      &:hover {
        path {
          fill: #9e00ff;
        }
      }
    }
    .reddit {
      &:hover {
        path {
          fill: #ff4500;
        }
      }
    }
    .telegram {
      &:hover {
        path {
          fill: #00c2ff;
        }
      }
    }
  }
}

@media only screen and (max-width: 1080px) {
  .footer-view {
    .footer {
      grid-template-columns: repeat(1, 1fr);
      padding: 36px 16px 60px 16px;
      grid-gap: 16px;
    }
    .links {
      grid-gap: 16px;
      padding: 20px 12px;
    }
  }
}
